/* COLORS */

:root {
  --dfa-yellow: #fcf539;
  --dfa-pink: #f6539e;
  --dfa-green: #b5e100;
  --dfa-blue: #1082d7;
  --dfa-orange: #fd861f;
  --dfa-purple: #c14ae1;
  --dfa-dark-yellow: #c08a00;
  --dfa-dark-pink: #aa195c;
  --dfa-dark-green: #5d7400;
  --dfa-dark-blue: #1b3862;
  --dfa-dark-orange: #a74d00;
  --dfa-dark-purple: #6f038b;
  --dfa-light-yellow: #fffb98;
  --dfa-light-pink: #ffadd3;
  --dfa-light-green: #e4ff75;
  --dfa-light-blue: #b4dfff;
  --dfa-light-orange: #ffcc6a;
  --dfa-light-purple: #eca4ff;
  --dfa-logo-bg: #1b3862;
}

.bg-blue {
  background-color: var(--dfa-dark-blue);
}

/* GLOBAL STYLES */

body {
  background-image: url('./img/bg.svg');
  background-size: 100%;
  /*background-attachment: fixed;*/
}

h1,
h2,
h3,
h4,
h5,
h6,
small,
a,
p {
  font-family: 'Josefin Sans', sans-serif;
}

/*
a.active, a:hover {
  text-decoration: underline !important;
}
*/

/* override bootstrap lead paragraph style */
.lead {
  font-weight: bold;
  font-size: 1.2rem;
}

img.content-img {
  object-fit: cover;
  width: 100%;
}

/* Navbar */
.navbar .navbar-brand {
  background-color: var(--dfa-logo-bg);
  padding: 0.5rem;
}

.navbar .navbar-nav .nav-item {
  margin: 0 3px 0 3px;
}

.navbar .navbar-nav .nav-link {
  /* override bootstrap */
  color: white;
  padding: 0.5rem;
}

.navbar.bg-blue .navbar-nav .nav-link:focus,
.navbar.bg-blue .navbar-nav .nav-link:hover,
.navbar.bg-blue .navbar-nav .nav-link.active {
  background-color: var(--dfa-dark-blue);
  color: white;
}

/* Carousel */

/* Declare heights because of positioning of img element */

.carousel-item {
  height: 38rem; /* height of carousel*/
  background-color: #777;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel-item-1 {
  background-image: url(./img/carousel_1.jpg);
}

.carousel-item-2 {
  background-image: url(./img/carousel_2.jpg);
}

.carousel-item-3 {
  background-image: url(./img/carousel_3.jpg);
}

.carousel-item-4 {
  background-image: url(./img/carousel_4.jpg);
}

.carousel-item-5 {
  background-image: url(./img/carousel_5.jpg);
}

.carousel-item-5 {
  background-image: url(./img/carousel_6.jpg);
  background-position: right 50% bottom 10%; /* for elizabeth's forehead lmao */
}

/* Content styles */

.postit-section {
  width: 80%;
  margin: 3rem auto 4rem auto;
  padding: 2rem;
  position: relative;
}

@media (max-width: 884px) {
  .postit-section {
    width: 90%;
  }
}

.corner-topleft {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
}

.corner-bottomleft {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
}

.postit-blue {
  background-color: var(--dfa-light-blue);
  box-shadow: 10px 10px 0 0 var(--dfa-dark-blue);
  color: black;
}

.postit-blue .corner-topleft {
  border-top: 2.5em solid var(--dfa-blue);
  border-right: 2.5em solid transparent;
}

.postit-blue .corner-bottomleft {
  border-bottom: 2.5em solid var(--dfa-blue);
  border-right: 2.5em solid transparent;
}

.btn,
.postit-blue .btn {
  background-color: var(--dfa-blue);
  color: white !important;
  font-family: 'Josefin Sans', sans-serif;
}

.brick-title {
  width: 15rem;
}

.brick-title img {
  width: 100%;
}

/* titles */

.title-current-members {
  transform: matrix(1, -0.04, 0.04, 1, 0, 0); /* tilt left */
  margin: 30px;
}

.title-alumni {
  transform: matrix(1, 0.04, -0.04, 1, 0, 0); /* tilt right */
  margin: 30px;
}

.group-photo {
  max-width: 100%;
}

.footer {
  color: white;
}

.about-headshots {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  justify-content: center;
  text-align: center;
}

.about-headshots img {
  width: 160px;
  border-radius: 50%;
  margin: 10px;
}

.about-headshots p {
  margin: 0 auto;
  width: 90%;
}

.about-header {
  margin-top: 48px;
}

.yale-logo {
  max-width: 100%;
}

.project-links {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.project-links img {
  width: 200px;
  margin: 15px;
  border-radius: 10px;
}

.project-pic {
  position: relative;
}

.project-pic_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  margin: 15px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
}

@media (hover: hover) {
  .project-pic_overlay:hover {
    opacity: 1;
  }
}

@media (hover: none) {
  .project-pic_overlay {
    opacity: 1;
  }
}

.project-pic_description,
.project-pic_year {
  font-size: 1.1em;
  text-align: center;
  margin: 5px;
  margin-top: auto;
}

.project-pic_description {
  font-weight: bold;
}

.home-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px;
  color: black;
  align-items: center;
}

.home-text {
  width: 50%;
  padding: 2rem;
}

.home-photo {
  width: 50%;
}

.home-button {
  width: auto;
  max-width: 100%;
  text-align: center;
  overflow: scroll;
}

@media (max-width: 884px) {
  .home-text {
    width: 100%;
    padding: 1rem;
  }
  .home-photo {
    width: 100%;
    padding: 5px;
  }
}

.outreach-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px;
  background-color: var(--dfa-light-blue);
  color: black;
  align-items: center;
}

.outreach-text {
  width: 50%;
  padding: 2rem;
  background-color: var(--dfa-light-blue);
}

.outreach-child {
  width: 50%;
}

@media (max-width: 880px) {
  .outreach-text {
    width: 100%;
  }
  .outreach-child {
    width: 100%;
  }
}

.outreach-postit {
  width: 100%;
  margin: 3rem auto 4rem auto;
  position: relative;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.icon-container img {
  width: 30%;
  object-fit: contain;
  padding: 20px;
}

.icon-description {
  width: 50%;
}

@media (max-width: 884px) {
  .icon-description {
    width: 100%;
    padding: 1rem;
  }
  .icon-container img {
    width: 51%;
    padding: 5px;
  }
}

.attribution h1 {
  padding: 10px;
}

.attribution p {
  margin-left: 10px;
}

.project-title,
.project-summary,
.project-description {
  text-align: center;
  background-color: transparent;
  margin: 20px;
}

.project-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.project-img img {
  max-width: 100%;
  height: 300px;
  margin: 20px;
}
